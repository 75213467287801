<template>
    <div class="w-full">

        <div class="flex justify-between w-full">
            <div class="mb-2">
                <div class="uppercase text-xs font-semibold">{{ field.humanName }}<span v-if="field.required" class="text-red-500 text-xl ml-1">*</span></div>
                <div v-if="field.description" class="text-xs mt-1 opacity-70">{{ field.description }}</div>
            </div>
            <!-- <div class="text-xs font-thin">({{field.inputType}})</div> -->
        </div>

        <div
            v-if="field.humanName == 'raw'"
            class="w-full rounded p-4 bg-white font-mono"
        >{{ JSON.stringify(value, 4) }}</div>

        <div v-else-if="field.humanName == 'materials'" class="rounded-lg bg-gray-200 dark:bg-gray-900 mt-2 flex flex-col p-2">
            <!-- <pre class="font-mono">{{ value }}</pre> -->
            <div v-for="m, i in value" :key="m" class="group hover:bg-gray-100 p-2 rounded">
                <div class="flex justify-between items-center">
                    <div><span class="font-semibold text-sm">{{ materials[m.id]?.item?.toTitleCase() }}</span>: {{ materials[m.id]?.type?.toTitleCase() }}</div>
                    <input @change="value[i].cost = Number($event.target.value)" :value="m.cost" type="number" class="w-24" />
                </div>
            </div>
        </div>
        <div v-else-if="field.humanName == 'tasks'" class="rounded-lg bg-gray-200 dark:bg-gray-900 mt-2 flex flex-col p-2">
            <!-- <pre class="font-mono">{{ value }}</pre> -->
            <div v-for="t, i in value" :key="t" class="group hover:bg-gray-100 p-2 rounded">
                <div class="flex justify-between items-center">
                    <div>{{ tasks[t.id].description.toTitleCase() }}</div>
                    <input @change="value[i].cost = Number($event.target.value)" :value="t.cost" type="number" class="w-24" />
                </div>
            </div>
        </div>

        <div
            v-else-if="$route.path.includes('opportunities') && ['knock','talk','walk','contingency','insurance Accepted','contract Signed', 'address Lockout'].includes(field.humanName)"
            class="w-full rounded p-4 bg-white font-mono"
        >
            <!-- <Toggle v-model="value" :small="false" background="bg-gray-100 dark:bg-gray-700" /> -->
            <Toggle :modelValue="!!value" @update:modelValue="value = $event ? 1 : 0" :small="false" background="bg-gray-100 dark:bg-gray-700" />
        </div>

        <div v-else-if="field.humanName == 'emoji'">
            <!-- <div v-for="v, e in value.reduce((a,e) => {a[e] += 1; return a}, {'🦁':0,'🐋':0,'🦣':0})">{{ e }}: {{v}}x</div> -->
            <div class="gap-2 flex flex-col">
                <div v-for="e in ['🦁','🐋','🦣']" :key="e" class="flex gap-2 items-center">
                    <button @click="value.pop(e)" class="px-3 rounded border">-</button>
                    <div class="text-2xl">{{ e }}</div>
                    <button @click="value.push(e)" class="px-3 rounded border">+</button>
                    <div class="pl-2">{{ value?.filter(v => v == e).length }}x</div>
                </div>
            </div>
        </div>

        <div v-else-if="field.humanName == 'xp'">
            <!-- <div v-for="v, e in value.reduce((a,e) => {a[e] += 1; return a}, {'🦁':0,'🐋':0,'🦣':0})">{{ e }}: {{v}}x</div> -->
            <div class="gap-2 flex">
                <button class="px-2 bg-red-50 rounded-lg border-2 text-xs border-red-200 font-semibold hidden sm:block" @click="value -= 1000">-1000</button>
                <button class="px-2 bg-red-50 rounded-lg border-2 text-xs border-red-200 font-semibold" @click="value -= 100">-100</button>
                <button class="px-2 bg-red-50 rounded-lg border-2 text-xs border-red-200 font-semibold" @click="value -= 10">-10</button>
                <button class="px-2 bg-red-50 rounded-lg border-2 text-xs border-red-200 font-semibold hidden sm:block" @click="value -= 1">-1</button>
                <input
                    v-model.number="value"
                    @change="value = (value && isNaN(value)) ? 0 : Number(value)"
                    class="px-3 py-2 rounded border w-20 text-center"
                />
                <button class="px-2 bg-red-50 rounded-lg border-2 text-xs border-red-200 font-semibold hidden sm:block" @click="value += 1">+1</button>
                <button class="px-2 bg-red-50 rounded-lg border-2 text-xs border-red-200 font-semibold" @click="value += 10">+10</button>
                <button class="px-2 bg-red-50 rounded-lg border-2 text-xs border-red-200 font-semibold" @click="value += 100">+100</button>
                <button class="px-2 bg-red-50 rounded-lg border-2 text-xs border-red-200 font-semibold hidden sm:block" @click="value += 1000">+1000</button>
            </div>
        </div>

        <Pills v-slot="{add}" v-model="value" v-else-if="field.array && ['text','number','datetime-local','checkbox'].includes(inputType)">

            <input
                v-if="['text','checkbox','number'].includes(inputType)"
                :type="inputType"
                :placeholder="field.humanName.toTitleCase()"
                @keydown.enter="add($event.target.value); $event.target.value = null"
                class="w-full"
            />
            <input
                v-else-if="['datetime-local'].includes(inputType)"
                @input="value = new Date($event.target.value)"
                @keydown.enter="add($event.target.value); $event.target.value = null"
                :type="inputType"
                class="w-full"
            />
            <!-- <select v-else-if="field.instanceOf == 'select'" @change="add" class="w-full p-4 outline-none">
                <option value="" disabled selected>Select a Role</option>
                <option :value="option" v-for="option in field.options" :key="option">{{ option?.replace(/([A-Z])/g, ' $1')?.toTitleCase() }}</option>
            </select> -->

        </Pills>

        <Address v-else-if="field.instanceOf == 'Address' && value?.hasOwnProperty?.('formatted')" v-model="value" class="w-full" />

        <div v-else-if="field.inputType == 'Boolean'" class="">
            <button
                @click="value = !value"
                class="w-14 p-1 flex justify-center items-center rounded-l-full rounded-r-full transition-all"
                :class="!value ? 'bg-gray-200 dark:bg-gray-700' : 'bg-green-500 dark:bg-green-800'"
            >
                <div class="w-6 h-6 bg-white dark:bg-gray-400 shrink-8 rounded-full transition-all" :class="!value ? '-translate-x-1/2' : 'translate-x-1/2'" />
            </button>
        </div>

        <input
            v-else-if="['text','checkbox','number'].includes(inputType)"
            v-model="value"
            :type="inputType"
            :placeholder="field.humanName.toTitleCase()"
            class="w-full"
        />

        <input
            v-else-if="['datetime-local'].includes(inputType)"
            :value="dayjs(value).format('YYYY-MM-DDTHH:mm')"
            @input="value = new Date($event.target.value)"
            :type="inputType"
            class="w-full"
        />

        <!-- <div v-else-if="inputType == 'relationship' && field.humanName == 'shotgun'" class="rounded-lg border dark:border-gray-600 p-4 overflow-visible">
            <MultiSelect
                v-model:modelSelectedEntries="value"
                :collection="field.relationship"
                :multiple="!field.single"
                :create="false"
            />
        </div> -->

        <div v-else-if="inputType == 'relationship'" class="rounded-lg border dark:border-gray-600 p-4 overflow-visible">
            <MultiSelect
                v-model:modelSelectedEntries="value"
                :collection="field.relationship"
                :multiple="!field.single"
                :create="false"
            />
        </div>

        <div v-else-if="inputType == 'image'" class="rounded-lg dark:border-gray-600">
            <UploadFile
                @url="value = $event"
                class="w-28 h-28 border-2 dark:border-gray-600 bg-gray-300 dark:bg-gray-700 block cursor-pointer"
            >
                <div v-if="!value && !currentValue" class="w-full h-full flex items-center justify-center">
                    <i class="fa fa-image text-3xl" />
                </div>
                <div
                    v-else class="w-full h-full bg-cover bg-center group relative overflow-hidden"
                    :style="`background-image: url('${currentValue?.url || value}')`"
                >
                    <div class="w-full h-full hidden group-hover:flex absolute backdrop-blur items-center justify-center">
                        <i class="fa fa-pencil text-3xl text-gray-800" />
                    </div>
                </div>
            </UploadFile>
            <input
                v-model="value"
                type="text"
                placeholder="Image URL"
                class="w-full mt-2"
            />
        </div>


        <Pills v-else-if="field.name == 'roles'" v-slot="{add}" v-model="value">
            <div class="rounded-lg border dark:border-gray-600 overflow-hidden pr-4 bg-white dark:bg-transparent">
                <select @change="add" class="w-full p-4 outline-none">
                    <option value="" disabled selected>Select a Role</option>
                    <option :value="option" v-for="option in field.options" :key="option">{{ option?.replace(/([A-Z])/g, ' $1')?.toTitleCase() }}</option>
                </select>
            </div>
        </Pills>

        <div v-else-if="inputType == 'select'" class="rounded-lg border dark:border-gray-600 overflow-hidden pr-4 bg-white dark:bg-transparent">
            <select v-model="value" class="w-full p-4 outline-none">
                <template v-if="typeof field.options[0] == 'object'">
                    <option :value="option.value" v-for="option in field.options" :key="option.value">{{ option.label }}</option>
                </template>
                <template v-else>
                    <option :value="option" v-for="option in field.options" :key="option">{{ option }}</option>
                </template>
            </select>
        </div>

        <!-- <QuillEditor
            v-else-if="inputType == 'richtext'"
            v-html="value"
            toolbar="full"
            v-model:content="value"
            contentType="html" theme="snow"
            class="article dark:text-gray-400 relative border dark:border-gray-600 p-2 rounded min-h-[8rem] max-h-[25vh] overflow-scroll bg-white dark:bg-gray-800"
        /> -->

        <!-- :init="{skin: darkMode.enabled ? 'oxide-dark' : null, content_css: darkMode.enabled ? 'dark' : null}" -->
        <!-- <Tiny
            v-else-if="inputType == 'richtext'"
            plugins="code link fullscreen paste image"
            :toolbar="null"
            api-key='iri3ane1rxbx5nlznkt8rpn2023fb3m4uzbdjsiyjzcjtr8e'
            output-format="html"
            v-model="value"
        /> -->
        <textarea
            v-else-if="inputType == 'richtext'"
            class="w-full rounded p-4"
            v-model="value"
            :v-html="value"
        />

        <template v-if="['materials','tasks'].includes(field.humanName)" />

        <div v-else-if="(field.instanceOf == 'Address') || (field.instanceOf != 'Address' && field.translate && currentValue && currentValue != 'null')" class="rounded-lg bg-gray-200 dark:bg-gray-900 p-4 mt-2 overflow-scroll max-h-[25vh]">
            <pre class="font-mono">{{ (currentValue) }}</pre>
        </div>


        <div v-else-if="field.array && mutableSubfields && typeof(value) == 'object'" class="p-4 dark:bg-gray-800/50 bg-gray-50 space-y-4 rounded-lg" >

            <ComplexArrayField v-for="item, index in value" :key="item" v-model="value" :index="index" :subfields="mutableSubfields" :collection="collection" />
            <button class="text-sm underline text-gray-500 ml-2" @click="value.push({})">Add {{ field.name }}</button>
        </div>

        <div v-else-if="mutableSubfields && typeof(value) == 'object' && value" class="p-4 dark:bg-gray-800/50 bg-gray-100 space-y-4 rounded-lg" >
            <Field
                v-for="subfield in mutableSubfields"
                :key="subfield.name"
                :field="subfield"
                :collection="collection"
                v-model="value[subfield.name]"
            />
        </div>

    </div>
</template>

<script>
import MultiSelect from './multiSelect.vue'
import {useModel} from './model'
import dayjs from 'dayjs'
import UploadFile from './uploadFile.vue'
import Pills from './pills.vue'
import ComplexArrayField from './complexArrayField.vue'
import Draggable from 'vuedraggable'
import Tiny from '@tinymce/tinymce-vue'
import Address from '../components/layout/address.vue'
import roles from '../../../config/helpers/roles'
import Toggle from '../components/partials/toggle.vue'
import {materials} from '../../../config/helpers/materials'
import {tasks} from '../../../config/helpers/tasks'

// import Field from './Field.vue'


export default {
    props: ['field','collection','modelValue','entry'],
    inject: ['darkMode','store'],
    components: { MultiSelect, UploadFile, Pills, ComplexArrayField, Draggable, Tiny, Address, Toggle },
    data() {
        return {
            currentValue: null,
            expanded: false,
            materials,
            tasks
        }
    },
	setup(props, { emit }) {
		return {
            value: useModel(props, emit, 'modelValue'),
		}
	},
    created() {
        if (this.field.instanceOf == 'Address' && !this.value) this.value = {formatted: ''}
        if (this.field.instanceOf == 'richText' && !this.value) this.value = ''
        if (this.field.inputType == 'DateTime' && !this.value) this.value = this.field.name == 'startDate' ? new Date() : null
        if (this.field.instanceOf == 'relationship' && !this.value) this.value = []
        if (this.field.instanceOf == 'select' && !this.value) this.value = []
        if (this.field.complex && !this.field.computed && !this.field.translate && !this.value) this.value = {}

        if (this.field.humanName == 'shotgun' && this.collection.name == 'opportunities' && this.entry.author) {
            console.log('Shotgun!', this.value)
            this.value.unshift(this.entry.author[0])
        }

        if (this.field.name == 'roles') {
            if (!this.store?.user?.roles?.includes('admin')) {
                this.field.options = this.field.options.filter(o => roles[o] <= this.store?.user?.level)
            }
        }
    },
    mounted() {
        if (
            this.field.translate &&
            this.field.instanceOf != 'relationship' &&
            this.field.instanceOf != 'select' &&
            this.field.instanceOf != 'Address' &&
            this.field.inputType != 'DateTime'
        ) {
            console.log(this.field.name)
            this.currentValue = this.modelValue
            this.value = null
        }
        if (this.field.instanceOf == 'Address') this.currentValue = this.modelValue
        if (this.field.complex && !this.field.computed && !this.field.translate && !this.value) this.value = {}
        if (this.field.array && !Array.isArray(this.value)) this.value = []

        // setTimeout(() => {
        this.$nextTick(() => {
            if (this.field.humanName == 'materials') {
                if (!Array.isArray(this.value)) this.value = []
                Object.keys(materials).filter(k => k != 'other').forEach(key => {
                    if (!this.value.find(x => x.id == key)) this.value.push({id: key, cost: null})
                })
            }
            if (this.field.humanName == 'tasks') {
                if (!Array.isArray(this.value)) this.value = []
                Object.keys(tasks).filter(k => k != 'other').forEach(key => {
                    if (!this.value.find(x => x.id == key)) this.value.push({id: key, cost: null})
                })
            }
        })
        // }, 1000)
    },
    // watch: {
    //     inputType: {
    //         handler() {
    //             if (this.inputType == 'relationship') {
    //                 this.value = []
    //             }
    //         },
    //         immediate: true
    //     }
    // },
    methods: {
        dayjs
    },
    computed: {
        inputType() {
            if (this.field.instanceOf == 'image') return 'image'
            if (this.field.instanceOf == 'select') return 'select'
            if (this.field.instanceOf == 'relationship') return 'relationship'
            if (this.field.instanceOf == 'richText') return 'richtext'
            let type = this.field.inputType?.toLowerCase()
            if (!type) return
            let typeMap = {
                string: "text",
                datetime: "datetime-local",
                boolean: "checkbox",
                float: "number",
                int: "number"
            }
            return typeMap[type]
        },
        mutableSubfields() {
            if (!this.field.complex || this.field.computed || this.field.translate) return
            return this.field.fields.filter(f => !f.computed && !f.global)
        },
    }
}
</script>

<style lang="postcss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;600&display=swap');

input, label {
    @apply border border-gray-300 rounded outline-blue-400 dark:outline-blue-500/40 px-3 py-2 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-600
}

select {
    @apply dark:bg-transparent
}
</style>
