<template>
    <div>
        <Hero title="Company Reports" class="border-b-4" />

        <div class="w-full max-w-4xl mx-auto px-4 flex flex-col py-16 gap-8">

            <div class="pb-8 border-b-2 w-full flex items-start gap-8">
                <div class="">
                    <div class="mb-2 uppercase text-sm font-semibold tracking-2xl opacity-50">Select a Date</div>
                    <input type="date" v-model="selectedDate" class="border-2 rounded px-3 py-[6px] text-sm" />
                </div>
            </div>

            <Mango
                v-slot="{data: reports, loading}"
                collection="companyReports" :id="null"
                :query="{
                    limit: 1000,
                    search: {
                        branch: branches,
                        compareCreated: {
                            // We have to create a day object so that CT is established
                            greaterThan: dayjs(selectedDate),
                            lessThan: dayjs(nextDay)
                        }
                    },
                    fields: [
                        'created',
                        'title',
                        'branch',
                        'knock',
                        'talk',
                        'walk',
                        'contingency',
                        'approvals',
                        'contracts',
                        'revenue',
                        'softSets',
                        'salesOpportunities',
                        'estemates',
                        'mtd',
                        'tracking'
                    ]
                }"
            >
                <Spinner v-if="loading" />
                <div v-else-if="reports.length" class="space-y-8">
                    <CompanyReport v-for="report in reports" :key="report.id" :report="report" />
                </div>
                <div v-else>
                    <div class="text-center text-xl opacity-20 font-bold">Nothing to show for this date</div>
                </div>
            </Mango>
        </div>

    </div>
</template>

<script>
import Hero from '../components/layout/hero.vue'
import MultiSelect from '../helpers/multiSelect.vue'
import CompanyReport from '../components/layout/company-report.vue'
import dayjs from 'dayjs'
import latestCutoff from '../helpers/latestCutoff'

export default {
	components: { Hero, MultiSelect, CompanyReport },
    inject: ['store','dayjs'],
    data() {
        return {
            branches: this.store?.user?.branches?.map(b => b.id) || [],
            selectedDate: dayjs(latestCutoff()).format('YYYY-MM-DD'),
        }
    },
    computed: {
        nextDay() {
            return this.dayjs(this.selectedDate).add(1, 'day').format('YYYY-MM-DD')
        }
    }
}
</script>

<style>

</style>
