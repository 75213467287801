<template>
    <!-- Hamburger Menu -->
    <div
        class="select-none border-r-4 top-14 border-ncfic-blue-100 overflow-y-scroll
        w-full sm:max-w-[16rem] md:transition-all transform-gpu duration-500 bg-white shadow-lg h-[calc(100vh-3.5rem)]
        dark:bg-gray-800 dark:border-gray-600 dark:text-ncfic-blue-100 flex flex-col
        "
        :class="store.showMenu ? '' : '-translate-x-full md:translate-x-0'"
        @click.stop
    >

        <button class="absolute top-0 right-0 cursor-pointer m-4 sm:hidden" @click="store.showMenu = false">
            <i class="fal fa-lg fa-times" />
        </button>

        <div class="md:mr-0 flex flex-col" @click="store.showMenu = false">

            <div class="flex flex-col text-sm space-y-2 py-8 px-5 border-y dark:border-gray-600 space-y-4">
                <!-- <div class="uppercase text-xs opacity-50 font-thin tracking-widest text-center mb-4">Team</div> -->
                <router-link
                    :to="`/manage-team/${branchId}`"
                    class="text-center rounded border mx-5 px-3 py-2 block"
                    v-html="`Manage Team${store?.user?.branches?.length > 1 ? 's' : ''}`"
                />
            </div>

            <div class="flex flex-col text-sm space-y-2 py-8 px-5 border-y dark:border-gray-600 space-y-4">
                <div class="uppercase text-xs opacity-50 font-thin tracking-widest text-center mb-4">Stats</div>
                <router-link
                    :to="`/branch-stats`"
                    class="text-center rounded border mt-12 sm:mt-8 mx-5 px-3 py-2 block"
                    v-html="`Branch Stats`"
                />
                <!-- <router-link
                    :to="`/team-stats/${branchId}`"
                    class="text-center rounded border mt-12 sm:mt-8 mx-5 px-3 py-2 block"
                    v-html="`Team Stats`"
                /> -->
                <router-link
                    :to="`/branchReports/view`"
                    class="text-center rounded border mt-12 sm:mt-8 mx-5 px-3 py-2 block"
                    v-html="`Branch Reports`"
                />
                <router-link
                    v-if="admin"
                    :to="`/companyReports/view`"
                    class="text-center rounded border mt-12 sm:mt-8 mx-5 px-3 py-2 block"
                    v-html="`Company Reports`"
                />
                <router-link
                    v-if="admin"
                    :to="`/opportunities/export`"
                    class="text-center rounded border mt-12 sm:mt-8 mx-5 px-3 py-2 block text-[13px]"
                    v-html="`Export Opportunities`"
                />
            </div>


            <div class="w-full flex flex-col text-sm space-y-2 py-8 px-5 border-y dark:border-gray-600" @click="store.showMenu = false">
                <div class="uppercase text-xs opacity-50 font-thin tracking-widest text-center mb-4">Manage Data</div>
                <!-- <input type="text" v-model="search" class="px-3 py-2 rounded border dark:border-gray-600 dark:bg-transparent my-8 " placeholder="Search Collections" /> -->
                <router-link
                    v-for="collection in filteredCollections"
                    class="border-b border-transparent px-3 py-2 hover:bg-gray-100 transition-all dark:hover:bg-gray-700 rounded-lg"
                    :key="collection.name"
                    :to="`/${collection.name}`"
                >
                    {{ collection.humanName }}
                </router-link>
            </div>

            <div class="w-full flex flex-col text-sm space-y-2 py-8 px-5 border-b dark:border-gray-600">
                <router-link to="/faq" class="block px-3 py-2 rounded border text-center">FAQ</router-link>
            </div>

            <!-- <div v-if="admin && ['655d2da19d3212a6fa7e1d4f','6398c4345aa6d4a4bf127cc1'].includes(store.user?.id)" class="w-full flex flex-col text-sm space-y-2 py-8 px-5 border-y dark:border-gray-600">
                <button @click="resendReports" class="px-3 py-2 rounded bg-red-500 text-white font-semibold">Resend Reports</button>
            </div> -->

        </div>

    </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    inject: ['store', 'darkMode', 'collections','axios'],
    data() {
        return {
            admin: false,
            search: '',
            hiddenCollections: [
                'teams',
                'failedOpportunities',
                'opportunitiesArchive',
                'carts',
                'items',
                'discounts',
                'companies',

                'repReports',
                'branchReports',
                'companyReports',

                'messages',
                'threads',

                // 'subs',
                // 'suppliers',
                'estimates',
            ]
        }
    },
    created() {
        if (!this.store?.user?.roles?.includes('admin')) {
            this.hiddenCollections.push('branches')
            this.hiddenCollections.push('companyReports')
        } else {
            this.admin = true
        }
    },
    methods: {
        async resendReports() {
            let approved = (await Swal.fire({
                title: 'Are you sure?',
                text: `This will delete all reports generated today and start fresh, resending reports to everyone. (it will take a minute...)`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, continue!',
                cancelButtonText: 'Nope, bad idea.'
            }))?.isConfirmed
            if (!approved) return
            let response = (await this.axios.post(`${this.store.api}/controllers/resendReport`))?.data
            if (!response?.success) return Swal.fire({icon: 'error', title: 'Error', text: response.message})
            Swal.fire({icon: 'success', title: 'Success', text: 'Reports have been resent.'})
        }
    },
    computed: {
        filteredCollections() {
            if (!this.search) return this.collections.filter(c =>  c.name[0] != '_' && !this.hiddenCollections.includes(c.name))
            return this.collections.filter(c => (!this.search || (c.name.toLowerCase().includes(this.search.toLowerCase()))))
        },
        branchId() {
            if (this.admin) return ''
            return this.store?.user?.branches?.length > 1 ? '' : this.store?.user?.branches?.[0]?.id
        }
    }
}
</script>

<style>

</style>
