<template>
    <Mango @update:data="setMember" :main="true" :suspend="true" class="w-full min-h-screen flex items-center flex-col p-4 md:p-16">
        <div v-if="member" class="w-full max-w-4xl space-y-8 md:space-y-16 py-8 md:py-16">

            <div class="flex items-center space-x-4 md:space-x-8">
                <People :people="[member]" size="w-28 h-28 md:w-32 md:h-32" class="shrink-0" />
                <img :src="`/s3/${member.image?.url?.split('.com/')[1]}`" ref="image" class="hidden" />
                <div class="w-full flex justify-between items-center">
                    <div>
                        <div class="opacity-30 text-sm">Joined: {{ dayjs(member?.created).format('MMM D, YYYY') }}</div>
                        <h1 class="text-xl md:text-2xl">{{ member.title }}</h1>
                        <div class="opacity-50">{{ member.branches?.[0]?.title }}</div>
                        <div v-if="member?.emoji" class="flex items-center space-x-2">
                            <div v-for="v, e in member.emoji.reduce((a,e) => {a[e] += 1; return a}, {'🦁':0,'🐋':0,'🦣':0})" :key="e" class="text-sm">
                                <div v-if="v">{{ e }} {{v}}x</div>
                            </div>
                        </div>
                        <button @click="addContact" class="md:hidden underline text-xs">Add Contact</button>
                    </div>
                    <button @click="addContact" class="hidden md:block border-b text-sm leading-tight pb-1">
                        <i class="fa fa-user mr-2" />Add Contact
                    </button>
                </div>
            </div>

            <div>
                <div class="uppercase text-xs tracking-widest opacity-50 mb-2">Date Range</div>
                <div class="text-xs">
                    <input type="date" v-model="rangeStart" @blur="getOpportunities()" class="border border-gray-300 rounded px-2 py-1" />
                    <span class="mx-2">to</span>
                    <input type="date" v-model="rangeEnd" @blur="getOpportunities()" class="border border-gray-300 rounded px-2 py-1" />
                </div>
            </div>

            <Spinner v-if="loadingOpportunities" :small="true" class="w-16 h-16" />
            <div v-else-if="!opportunities.length && !repReports.length" class="text-center text-xl opacity-20 font-bold">Nothing to show for this date range</div>
            <div v-else class="!mt-4 md:!mt-8">

                <div class="order-1 md:order-3">
                    <div class="p-4 md:p-8 rounded-lg shadow-card dark:bg-gray-900">
                        <div class="font-semibold text-xs tracking-xl uppercase opacity-40 text-center">Stats</div>
                        <!-- <div class="text-center">Total: {{ opportunities.length }}</div> -->
                        <div class="text-sm mt-4 w-full flex flex-col justify-center">

                            <div v-if="opportunities.length || repReports.length" class="flex space-x-4 font-mono mx-auto mb-4">
                                <div class="bg-red-50 w-14 h-14 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                                    <div class="text-xs tracking-widest uppercase leading-tight opacity-50">Knock</div>
                                    <div class="">{{ numbers.knock || 0 }}</div>
                                </div>
                                <div class="bg-red-50 w-14 h-14 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                                    <div class="text-xs tracking-widest uppercase leading-tight opacity-50">talk</div>
                                    <div class="">{{ numbers.talk || 0 }}</div>
                                </div>
                                <div class="bg-red-50 w-14 h-14 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                                    <div class="text-xs tracking-widest uppercase leading-tight opacity-50">walk</div>
                                    <div class="">{{ numbers.walk || 0 }}</div>
                                </div>
                                <div class="bg-red-50 w-14 h-14 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                                    <div class="text-xs tracking-widest uppercase leading-tight opacity-50">ctgy</div>
                                    <div class="">{{ numbers.contingency || 0 }}</div>
                                </div>
                            </div>

                            <template v-for="number, label in numbers" :key="label">
                                <div v-if="!['knock','talk','walk','contingency'].includes(label)" class="flex justify-between items-center mt-1">
                                    <span class="font-semibold text-xs md:text-sm opacity-80">{{ label }}: </span>
                                    <div class="flex-grow mx-2 border-b border-dotted border-gray-500/50" />
                                    <span class="font-mono md:text-base">{{ number }}</span>
                                </div>
                            </template>

                        </div>
                    </div>
                </div>

                <div class="shadow-lg p-4 md:p-8 rounded-lg mt-16 bg-white">
                    <ReportChart type="rep" />
                </div>

                <div v-if="opportunities.length" class="divide-y shadow-lg py-4 rounded-lg mt-16 bg-white">
                    <router-link :to="`/opportunities/${o.id}`" v-for="o in opportunities" :key="o.id" class="px-8 py-3 block group">
                        <div class="flex justify-between mb-2">
                            <!-- <pre>{{ o.ktwc?.contingency}} -- {{ o.timestamps }}</pre>
                            <pre>{{ o.editor?.id }} {{ o.editId }}</pre> -->
                            <div>{{ o.ownerInfo?.lastName }} <span v-if="o.revenue"> - {{ o.revenue?.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) }}</span></div>
                            <div class="ml-2">
                                <div
                                    class="text-2xs px-1 py-[2px] rounded whitespace-nowrap"
                                    :class="{
                                        'border border-green-500/50 text-green-500 bg-green-50': ['open','featured','Insurance Accepted','Contract Signed','Contingency'].includes(o.status),
                                        'border border-yellow-400/50 text-yellow-500 bg-yellow-50': ['pending','Soft Set'].includes(o.status),
                                        'border border-orange-400/50 text-orange-400 bg-orange-50': o.status == 'hidden',
                                        'border border-red-400/50 text-red-400 bg-red-50': ['closed','Lockout'].includes(o.status),
                                        'text-black bg-black/5': ['Skip','No Answer','Not Interested'].includes(o.status),
                                    }"
                                >
                                    {{ o.status || 'Skip' }}
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-between">
                            <div class="text-xs group-hover:underline">{{ o.title }}</div>
                            <div class="text-sm">{{ dayjs(o.updated).format('MM/DD h:mma') }}</div>
                        </div>
                        <div class="flex justify-between">
                            <!-- <div>{{ o.timestamps }}</div> -->
                        </div>
                    </router-link>
                </div>

            </div>

        </div>
    </Mango>
</template>

<script>
import Mango from '../helpers/mango'
import People from '../components/layout/people.vue'
import createVcard from '../helpers/createVcard'
import dayjs from 'dayjs'
import latestCutoff from '../helpers/latestCutoff'
import ReportChart from '../components/layout/reportChart.vue'

export default {
	components: { People, ReportChart },
    data() {
        return {
            member: null,
            loadingOpportunities: false,
            opportunities: [],
            repReports: [],
            rangeStart: dayjs().subtract(14, 'days').format('YYYY-MM-DD'),
            rangeEnd: dayjs().format('YYYY-MM-DD'),
        }
    },
    // Happens too fast
    // watch: {
    //     rangeStart: 'getReports',
    //     rangeEnd: 'getReports',
    // },
    methods: {
        dayjs,
        getProgressionValue(o) {
            if (['open','featured','Insurance Accepted','Contract Signed','Contingency'].includes(o.status)) return 5
            if (['pending','Soft Set'].includes(o.status)) return 4
            if (o.status == 'hidden') return 3
            if (['closed','Lockout'].includes(o.status)) return 2
            if (['Skip','No Answer','Not Interested'].includes(o.status)) return 1
        },
        async setMember(member) {
            this.member = member
            this.getOpportunities()
        },
        async addContact() {

            let vCardData = await createVcard({ member: this.member, image: this.$refs.image })

            const fileName = `${this.member.firstName}-${this.member.lastName}.vcf`;

            let vCardBlob = new Blob([vCardData], { type: 'text/vcard' });
            let vCardUrl = URL.createObjectURL(vCardBlob);
            // return URL.createObjectURL(vCardBlob);

            var link = document.createElement('a');
            link.href = vCardUrl;
            link.download = fileName;
            link.textContent = 'Download vCard';
            document.body.appendChild(link);
            link.click()
            document.body.removeChild(link);

        },
        inRange(l, param) {
            param = l?.timestamps?.[param] || l?.[param]
            param = param ? new Date(param) : null
            return param > this.startRange && param < this.endRange
        },
        async getOpportunities() {
            if (!this.member?.id) return
            this.loadingOpportunities = true

            let startRange = this.startRange
            let endRange = this.endRange

            this.opportunities = (await Mango.opportunities({
                search: {
                    $and: [
                        {
                            $or: [
                                { "timestamps.knock": { $gt: startRange, $lt: endRange } },
                                { "timestamps.talk": { $gt: startRange, $lt: endRange } },
                                { "timestamps.walk": { $gt: startRange, $lt: endRange } },
                                { "timestamps.contingency": { $gt: startRange, $lt: endRange } },
                                { "timestamps.revenue": { $gt: startRange, $lt: endRange } },
                                { "timestamps.softSet": { $gt: startRange, $lt: endRange } },
                                { "timestamps.estimate": { $gt: startRange, $lt: endRange } },
                                { insuranceAccepted: { $gt: startRange, $lt: endRange } },
                                { contractSigned: { $gt: startRange, $lt: endRange } },
                                { created: { $gt: startRange, $lt: endRange } },
                            ],
                        },
                        {
                            $or: [{ authorId: this.member.id }, { shotgun: this.member.id }]
                        },
                    ]
                },
                fields: ['title','status','revenue','updated','ownerInfo','timestamps','ktwc','contractSigned','insuranceAccepted','estimate','inspection','discussion','created'],
                sort: {updated: -1},
                limit: 100000
            }))

            // Sort by progression (descending) and then by address
            this.opportunities.sort((a, b) => {
                const progressionA = this.getProgressionValue(a);
                const progressionB = this.getProgressionValue(b);
                if (progressionA !== progressionB) {
                    return progressionB - progressionA; // Descending order of progression
                }
            });

            this.loadingOpportunities = false
        },
        // async getReports() {
        //     if (!this.member?.id) return
        //     this.loadingOpportunities = true

        //     this.repReports = (await Mango.repReports({
        //         search: {
        //             rep: this.member.id,
        //             // where: "this.ktwc && this.ktwc.knock",
        //             compareCreated: {
        //                 greaterThan: dayjs(startRange),
        //  Add a day so that it includes the end date
        //                 lessThan: dayjs(this.endRange).add(1, 'day')
        //             },
        //         },
        //         sort: {created: 1},
        //         limit: 100000
        //     }))

        //     this.loadingOpportunities = false
        // }
    },
    computed: {
        startRange() { return latestCutoff(this.rangeStart + ' 00:00')},
        endRange() { return latestCutoff(this.rangeEnd + ' 20:00')},
        ktwc() {
            return {
                knock: this.opportunities.filter(l => this.inRange(l, 'knock')).filter(l => !!l.ktwc?.knock).length,
                talk: this.opportunities.filter(l => this.inRange(l, 'talk')).filter(l => !!l.ktwc?.talk).length,
                walk: this.opportunities.filter(l => this.inRange(l, 'walk')).filter(l => !!l.ktwc?.walk).length,
                contingency: this.opportunities.filter(l => this.inRange(l, 'contingency')).filter(l => !!l.ktwc?.contingency).length,
            }
        },
        daysActive() {
            let days = this.opportunities.map(l => dayjs(l.created).format('YYYY-MM-DD'))
            return Array.from(new Set(days)).length
        },
        numbers() {
            return {
                ...this.ktwc,
                'Sales Opportunities': this.opportunities.length,
                'Soft Sets': this.opportunities.filter(l => l?.inspection?.toLowerCase?.() === 'soft set' || l?.discussion?.toLowerCase?.() === 'soft set').length,
                Contracts: this.opportunities.filter(l => !!l.contractSigned).length,
                Approvals: this.opportunities.filter(l => !!l.insuranceAccepted).length,
                Estimates: this.opportunities.reduce((a, l) => a + (l.estimate || 0), 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }),
                Revenue: this.opportunities.reduce((a, l) => a + (l.revenue || 0), 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }),
                'Conversion Rate': ((this.ktwc.contingency / this.opportunities.length)*100).toFixed(0) + '%',
                'Knocks to Contingency': (this.ktwc.knock / this.ktwc.contingency).toFixed(1),
                'Average Knocks': (this.opportunities.filter(l => this.inRange(l, 'knock')).length / this.daysActive).toFixed(0) + '/day',
            }
        },

    }
}
</script>

<style>

</style>
