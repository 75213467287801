<template>
    <div v-if="collectionModel">
        <Hero :title="collectionModel.humanName" :subtitle="null" class="border-b-4 dark:border-gray-800" />
        <div class="w-full flex flex-col items-center p-4 sm:p-8 md:p-16">
            <div class="w-full max-w-7xl flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 justify-between mb-4 items-center">
                <router-link
                     v-if="(admin || ['branches','subs','suppliers'].includes(collectionModel.name)) && !collectionModel.name.includes('Reports')"
                     :to="`/${collectionModel.name}/create`"
                     class="px-3 py-2 bg-ncfic-green-500 dark:opacity-80 text-white rounded mx-auto md:m-0"
                     v-html="`New ${collectionModel.humanSingular}`"
                />
                <div v-else></div>
                <input v-if="collectionModel?.algolia != false" type="text" v-model="search" class="px-3 py-2 border dark:border-gray-800 rounded w-full max-w-sm dark:bg-transparent" placeholder="Search" />
                <div v-else-if="collectionModel.name == 'opportunities'">
                    <button @click="showFilters = true" class="px-3 py-2 border rounded flex items-center space-x-2"><span>Search</span><i class="fal fa-cog text-xl text-gray-500" /></button>
                    <Modal :active="showFilters" @hide="showFilters = false" class="filters" maxWidth="max-w-2xl">

                        <h2 class="text-2xl text-center">Advanced Filters</h2>
                        <button @click="clearFilters" class="text-red-500 text-center w-full !mt-1 underline">Clear</button>

                        <!-- <div class="">
                            <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Full Address</div>
                            <VagueAddress v-model="filters.address" />
                        </div> -->

                        <div v-if="admin || branches?.length > 1" class="rounded-lg border dark:border-gray-600 p-4 overflow-visible">
                            <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Branch</div>
                            <MultiSelect
                                v-model:modelSelectedIds="filters.branch"
                                v-model:modelSelectedEntries="branchEntries"
                                :algoliaFilters="branchSearchFilters"
                                collection="branches"
                                :multiple="true"
                                :create="false"
                            />
                        </div>

                        <div class="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                            <div class="w-full md:w-1/3">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Number</div>
                                <input v-model="filters.streetNumber" placeholder="Number" />
                            </div>

                            <div class="md:flex-grow">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Street</div>
                                <input v-model="filters.street" placeholder="Street" />
                            </div>
                        </div>

                        <div class="">
                            <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">City</div>
                            <input v-model="filters.city" placeholder="City" />
                        </div>

                        <div class="">
                            <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Owner Last Name</div>
                            <input v-model="filters.owner" placeholder="Last Name" />
                        </div>

                        <div class="">
                            <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Revenue</div>
                            <div class="flex items-center space-x-4">
                                <input v-model.number="filters.revenueMin" placeholder="Min" />
                                <input v-model.number="filters.revenueMax" placeholder="Max" />
                            </div>
                        </div>

                        <div class="">
                            <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Estimate</div>
                            <div class="flex items-center space-x-4">
                                <input v-model.number="filters.estimateMin" placeholder="Min" />
                                <input v-model.number="filters.estimateMax" placeholder="Max" />
                            </div>
                        </div>

                        <div class="">
                            <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Entry Date Range</div>
                            <div class="text-xs flex space-x-4 items-center">
                                <input type="date" v-model="filters.rangeStart" class="border border-gray-300 rounded px-2 py-1" />
                                <!-- <span class="mx-2">to</span> -->
                                <input type="date" v-model="filters.rangeEnd" class="border border-gray-300 rounded px-2 py-1" />
                            </div>
                        </div>

                        <div class="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                            <div class="w-full md:w-1/2">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Only Contract Signed</div>
                                <Toggle v-model="filters.contractSigned" :small="false" background="bg-gray-100 dark:bg-gray-700" />
                            </div>

                            <div class="w-full md:w-1/2">
                                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Only Insurance Accepted</div>
                                <Toggle v-model="filters.insuranceAccepted" :small="false" background="bg-gray-100 dark:bg-gray-700" />
                            </div>
                        </div>

                        <button @click="exportOpportunities" class="rounded flex items-center space-x-2 underline text-blue-500"><span>Download Opportunities</span><i class="fal fa-download text-xl" /></button>

                        <div v-if="downloading" class="absolute w-full h-full inset-0 bg-black/50 flex items-center justify-center backdrop-blur-[2px]">
                            <div class="text-2xl font-bold text-white">Downloading Opportunities</div>
                        </div>

                    </Modal>
                </div>
            </div>
            <Mango
                :collection="collectionModel.name"
                class="max-w-7xl w-full"
                :class="{'opacity-40 pointer-events-none': working}"
                :id="null"
                :algoliaSearch="algoliaSearch"
                :algoliaFilters="algoliaFilters"
                :infinite="true"
                v-slot="{loading}"
                @update:data="data = $event"
                :suspend="true"
                :query="query"
            >
                <Spinner v-if="loading" />
                <div v-else class="divide-y dark:divide-gray-800 w-full">
                    <!-- Headers -->
                    <div class="w-full flex justify-between p-2 px-0 md:p-4 opacity-75 uppercase text-xs font-semibold space-x-4">
                        <div @click="sort('title')" class="flex-grow">Title</div>
                        <div v-for="field in collectionModel?.adminIndex" :key="field" @click="sort(field)">
                            {{ field }}
                        </div>
                        <div @click="sort('status')" class="">Status</div>
                    </div>

                    <template v-if="collectionModel.name == 'registrations'" >
                        <!-- <Registration v-for="entry in data?.registrations || data" :key="entry.id" :entry="entry" :collectionModel="collectionModel" /> -->
                    </template>

                    <template v-else>
                        <router-link :to="`/${collectionModel.name}/${entry.id}`" v-for="entry in data" :key="entry.id" class="p-4 space-x-4 items-center flex group hover:bg-gray-50 dark:hover:bg-gray-800/50">

                            <div class="w-16 h-16 rounded shrink-0 border-2 border-white dark:border-gray-400 bg-gray-800 uppercase text-white relative overflow-hidden flex items-center justify-center">
                                <div v-if="entry?.image?.url" class="bg-cover bg-center w-full h-full absolute inset-0" :style="`background-image: url('${entry?.image?.url}')`" />
                                <div v-else-if="entry?.selfie?.url" class="bg-cover bg-center w-full h-full absolute inset-0" :style="`background-image: url('${entry?.selfie?.url}')`" />
                                <div v-else-if="entry?.bannerImage?.url" class="bg-cover bg-center w-full h-full absolute inset-0" :style="`background-image: url('${entry?.bannerImage?.url}')`" />
                                <div v-else-if="entry.title">{{ entry?.title?.[0] + (entry?.title?.includes(' ') ? entry?.title?.split(' ')?.[1]?.[0] : '') }}</div>
                                <div v-else><i class="fa fa-question" /></div>
                                <div class="hidden dark:block w-full h-full absolute inset-0 bg-gray-800 opacity-20" />
                            </div>

                            <div class="flex-grow truncate">
                                <div class="group-hover:underline truncate">{{ entry.title }}</div>
                                <div class="text-xs text-gray-500">{{ dayjs(entry?.startDate || entry?.created).format('MMM. D, YYYY') }}</div>
                                <div v-if="entry.ownerInfo?.lastName" class="text-xs text-gray-500">{{ entry.ownerInfo?.firstName || '' }} {{ entry.ownerInfo?.lastName }}</div>
                            </div>

                            <!-- Custom Layout based on Config -->
                            <div v-for="field in collectionModel?.adminIndex" :key="field" class="text-sm">
                                <div v-if="entry[field]">{{ dayjs(entry[field]).format('MMM. D, YYYY') }}</div>
                            </div>

                            <div class="flex items-center group-hover:hidden shrink-0">
                                <div class="text-xs">
                                    {{ entry?.roles?.join(', ')?.replace(/([A-Z])/g, ' $1')?.toTitleCase() || entry.status || '' }}
                                    <i
                                        class="fa fa-circle ml-2 text-3xs relative"
                                        :class="{
                                            'text-green-500': ['open','featured','Insurance Accepted','Contract Signed','Contingency'].includes(entry.status),
                                            'text-yellow-400': ['pending','Soft Set'].includes(entry.status),
                                            'text-orange-400': entry.status == 'hidden',
                                            'text-red-400': ['closed','Lockout'].includes(entry.status),
                                            'text-black': ['Skip','No Answer','Not Interested'].includes(entry.status),
                                        }"
                                    >
                                        <i v-if="entry.status == 'featured'" class="fa fa-circle text-3xs text-green-400 animate-ping absolute inset-0" />
                                    </i>
                                </div>
                            </div>

                            <div class="items-center hidden group-hover:flex space-x-4 items-center">
                                <button v-if="collectionModel.name == 'opportunities' || store.user?.email == 'cneifert@ncfic.org'" v-tooltip="`Delete`" class="hover:text-red-500" @click.stop.prevent="remove(entry)">
                                    <i class="fad fa-trash-alt" />
                                </button>
                                <a v-if="collectionModel.name == 'members'" v-tooltip="`View Report`" @click.stop :href="`/members/${entry.id}/report`" target="_blank" class="hover:text-sky-500">
                                    <i class="fad fa-scroll" />
                                </a>
                                <a v-if="collectionModel.name == 'branches'" v-tooltip="`View Report`" @click.stop :href="`/branchReports/view`" target="_blank" class="hover:text-sky-500">
                                    <i class="fad fa-scroll" />
                                </a>
                                <!-- <button class="hover:text-green-500" v-tooltip="`Duplicate`" @click.stop.prevent="duplicate(entry)">
                                    <i class="fad fa-clone" />
                                </button> -->
                            </div>

                        </router-link>
                    </template>

                </div>
            </Mango>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Hero from '../components/layout/hero.vue'
import Mango from './mango'
import dayjs from 'dayjs'
import Toggle from '../components/partials/toggle.vue'
import Modal from '../components/layout/modal.vue'
import MultiSelect from './multiSelect.vue'
// import VagueAddress from '../../../front/src/components/layout/vagueAddress.vue'
import downloadCsv from './download-csv.js'

let baseFilters = {
    branch: [],
    streetNumber: null,
    street: null,
    city: null,
    owner: null,
    contractSigned: null,
    insuranceAccepted: null,
    revenueMin: null,
    revenueMax: null,
    estimateMin: null,
    estimateMax: null,
    address: {
        formatted: null
    },
    rangeStart: dayjs().subtract(3, 'months').format('YYYY-MM-DD'),
    rangeEnd: dayjs().format('YYYY-MM-DD'),
}

export default {
  components: { Hero, Toggle, Modal, MultiSelect },
    props: ['collection'],
    inject: ['axios','store'],
    data() {
        return {
            search: null,
            data: null,
            branchEntries: [],

            baseFilters,
            showFilters: false,
            downloading: false,
            filters: JSON.parse(JSON.stringify(baseFilters)),
        }
    },
    computed: {
        algoliaSearch() { return this.collectionModel?.algolia === false ? null : this.search },
        collectionModel() {
            let inferedCollection = this.collection || this.$route.path.split('/')?.[1] || null
            let validatedCollection = Mango.collections.find(c => c.name == inferedCollection)
            if (!validatedCollection) return console.error(`🥭 ${inferedCollection} is not a valid collection.`)
            return validatedCollection
        },
        admin() {
            return this.store?.user?.roles?.includes('admin')
        },
        branches() {
            if (!this.admin) {
                return this.store?.user?.branches || []
            }
        },
        query() {
            if (this.algoliaSearch) return
            let query = {limit: 50, search: {}, sort: {created: -1}}
            // if (this.collectionModel?.adminIndex) query.fields = [...query.fields, ...this.collectionModel.adminIndex]
            // if (this.search && !this.collectionModel.algoliaModel) query.search.wordSearch = this.search
            query.fields = ['title','status','image','bannerImage','startDate','endDate','created','updated','selfie','author','roles','ownerInfo']
            if (Array.isArray(this.collectionModel?.adminIndex)) {
                query.fields = [...query.fields, ...this.collectionModel.adminIndex]
            }
            if (this.branches) {
                if (this.collectionModel.name == 'members') query.search.branches = {$in: this.branches.map(b => b.id)}
                else query.search.branch = {$in: this.branches.map(b => b.id)}
            }

            if (this.filterSearch) query.search = {...query.search, ...this.filterSearch}
            return query
        },
        algoliaFilters() {
            if (this.algoliaSearch && this.branches) {
                if (this.collectionModel.name == 'members') {
                    return this.branches.map(b => `branches:${b.id} OR branches.id:${b.id}`).join(' OR ')
                } else {
                    return this.branches.map(b => `branch:${b.id} OR branch.id:${b.id}`).join(' OR ')
                }
            }
        },
        branchSearchFilters() {
            if (this.branches) {
                return this.branches.map(branch => `objectID:${branch.id}`).join(' OR ');
            }
        },
        filterSearch() {
            if (this.collectionModel.name != 'opportunities') return
            let search = {}
            if (this.filters.branch?.length) search.branch = {$in: this.filters.branch}
            if (this.filters.address?.id) search['address.id'] = this.filters.address.id
            if (this.filters?.streetNumber) search['address.number'] = this.filters.streetNumber
            if (this.filters?.street) search['address.street'] = {$regex: this.filters.street, $options: 'i'}
            if (this.filters?.city) search['address.city'] = {$regex: this.filters.city, $options: 'i'}
            if (this.filters?.owner) search['ownerInfo.lastName'] = {$regex: this.filters.owner, $options: 'i'}
            if (this.filters?.contractSigned) search.contractSigned = {$ne: null}
            if (this.filters?.insuranceAccepted) search.insuranceAccepted = {$ne: null}
            if (this.filters?.revenueMin) search.revenue = {$gte: this.filters.revenueMin}
            if (this.filters?.revenueMax) search.revenue = {...(search?.revenue || {}), $lte: this.filters.revenueMax}
            if (this.filters?.estimateMin) search.estimate = {$gte: this.filters.estimateMin}
            if (this.filters?.estimateMax) search.estimate = {...(search?.estimate || {}), $lte: this.filters.estimateMax}
            if (this.filters?.rangeStart) search.compareCreated = {greaterThan: dayjs(this.filters.rangeStart)}
            if (this.filters?.rangeEnd) search.compareCreated ? search.compareCreated.lessThan = dayjs(this.filters.rangeEnd).add(1, 'day') : {lessThan: dayjs(this.filters.rangeEnd).add(1, 'day')}
            return search
        },
    },
    methods: {
        dayjs,
        async exportOpportunities() {
            this.downloading = true
            let query = {...this.query}
            query.fields = ['address','title','status','revenue','estimate','created','updated','contractSigned','insuranceAccepted','shotgun','ownerInfo','author']
            query.limit = 10000
            let opportunities = await Mango.opportunities(query)
            let csvContent = "data:text/csv;charset=utf-8,"
                + "Owner Name,Address,City,State,Status,Revenue,Reps,Created,Updated\n"
                + opportunities?.map(o => {
                    return `
                        ${(`${o?.ownerInfo?.firstName || ''} ${o.ownerInfo?.lastName || 'No Name'}`)?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '')},
                        ${o.address?.address?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '') || ''},
                        ${o.address?.city?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '') || ''},
                        ${o.address?.state?.trim?.()?.replace?.(/[^a-zA-Z0-9\s\-]/g, '') || ''},
                        ${o.status || ''},
                        $${o.revenue || '0'},
                        ${[...(o?.shotgun || []), o.author]?.map(s => s?.firstName + ' ' + s?.lastName).join(' / ') || ''},
                        ${dayjs(o.created).format('MMM. D YYYY')},
                        ${dayjs(o.updated).format('MMM. D YYYY')}
                    `.replace(/\n/g, '').replace(/\s+/g, ' ').replaceAll('", "', '","')
                }).join('\n')
            downloadCsv(csvContent, `Opportunities.csv`)
            this.downloading = false
        },
        clearFilters() {
            this.filters = JSON.parse(JSON.stringify(baseFilters))
            this.branchEntries = []
            this.showFilters = false
        },
        sort(field) {
            return
            if (!this.query?.sort) this.query.sort = {}
            // if (this.query.sort?.[field])
            this.query.sort[field] = this.query.sort?.[field] === 1 ? -1 : 1
            for (let key in this.query.sort) {
                if (key != field) delete this.query.sort[key]
            }
        },
        async remove(entry) {
            this.working = true
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response = await Mango[this.collectionModel.name].delete(entry.id)
                    if (response.deleted == 1) {
                        Swal.fire(
                            'Deleted!',
                            'Your entry has been deleted.',
                            'success'
                        )
                        this.working = false
                        this.data.splice([this.data.findIndex(e => e.id == entry.id)], 1)
                    } else {
                        Swal.fire(
                            'Error!',
                            response?.response,
                            'error'
                        )
                        this.working = false
                    }
                } else {
                    this.working = false
                }
            })
        },
        async duplicate(entry) {
            this.working = true
            let newEntry = (await this.axios.get(`${this.store.api}/${this.collectionModel.name}/${entry.id}?depthLimit=0`))?.data?.response
            console.log('newEntry', newEntry)
            if (!newEntry.id) {
                this.working = false
                return Swal.fire(
                    'Error!',
                    'There was an error duplicating this entry.',
                    'error'
                )
            }

            delete newEntry.id
            delete newEntry._id
            delete newEntry.algoliaId
            delete newEntry.collection

            for (let field of (this.collectionModel.fields.filter(f => f.computed || f.relationship) || [])) {
                delete newEntry[field.name]
            }

            for (let field in newEntry) {
                if (!this.collectionModel.fields.some(f => f.name == field)) {
                    delete newEntry[field]
                }
            }

            let response = (await this.axios.post(`${this.store.api}/${this.collectionModel.name}`, newEntry))?.data?.response

            if (!response.id) {
                this.working = false
                return Swal.fire(
                    'Error!',
                    'There was an error duplicating this entry.',
                    'error'
                )
            }

            Swal.fire(
                'Duplicated!',
                'Your document has been duplicated.',
                'success'
            )

            this.data.unshift(response)
            this.working = false
        }
    }
}
</script>

<style lang="postcss" scoped>
.filters input {
    @apply border border-gray-300 rounded outline-blue-400 dark:outline-blue-500/40 px-3 py-2 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-600 w-full
}
</style>
